import React, { FC, useState } from 'react';
import { useFormik } from 'formik';
import TextInput from '../common/InputFields/TextInput';
import Button from '../common/Buttons/Button';
import emailSchema from '../../utils/ValidationSchema/emailValidation';
import ErrorMsg from '../common/ErrorMsg';
import { checkUser } from '../../services/ForgotPasswordServices';
import { toast } from 'react-toastify';
import { useLocation } from '@reach/router';
import { Link, navigate } from 'gatsby';

const ForgotPasswordContent = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const utmUrlParams = location.search;
  const [passwordReset, setPasswordReset] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: emailSchema,
    onSubmit: async (values, { resetForm }) => {
      // alert(JSON.stringify(values, null, 2));
      setLoading(true);
      const { email } = values;
      let data = {
        email: email,
        paramsVal: utmUrlParams,
      };
      // console.log(data);
      await checkUser(data)
        .then((res: any) => {
          if (res.data.reset_url) {
            setPasswordReset(true);
            resetForm();
          }
        })
        .catch((err) =>
          // console.error("There was an error in fetching userData", err)
          toast.error(err.msg)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <div className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] lg:mt-[60px] mt-[20px] lg:mb-[80px] mb-[30px] contain log-ui">
      <h1 className="md:text-center text-left text-[40px]  md:mb-5 mb-2 text-black font-sofiaSemibold font-semiBold ">
        Forgot Password
      </h1>
      <div className="bg-[#F4F4F4] lg:py-[25px] pt-[25px]  lg:px-[25px] mx-auto xl:w-[908px] md:max-w-[908px] md:w-[90%] w-full rounded-[21px]">
        <div className="success-notice md:px-[20px] px-[15px]  ">
          {passwordReset ? (
            <div className="bg-[#e8f9f4]  border-l-4 border-[#009a6c]  text-black px-4 py-2 shadow-sm flex lg:items-center items-start">
              <div className="lg:mt-0 mt-1">
                <svg
                  className="fill-current h-5 w-5 text-[#009a6c] mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                >
                  <path d="M 12 2 C 6.486 2 2 6.486 2 12 C 2 17.514 6.486 22 12 22 C 17.514 22 22 17.514 22 12 C 22 10.874 21.803984 9.7942031 21.458984 8.7832031 L 19.839844 10.402344 C 19.944844 10.918344 20 11.453 20 12 C 20 16.411 16.411 20 12 20 C 7.589 20 4 16.411 4 12 C 4 7.589 7.589 4 12 4 C 13.633 4 15.151922 4.4938906 16.419922 5.3378906 L 17.851562 3.90625 C 16.203562 2.71225 14.185 2 12 2 z M 21.292969 3.2929688 L 11 13.585938 L 7.7070312 10.292969 L 6.2929688 11.707031 L 11 16.414062 L 22.707031 4.7070312 L 21.292969 3.2929688 z"></path>
                </svg>
              </div>
              <p className="font-sofiaRegular font-regular text-[16px] ">
                Please check your inbox. You will receive an email with the
                reset password URL.
              </p>
            </div>
          ) : null}
        </div>
        <div className="w-full mx-auto md:px-[20px] py-[30px] px-[15px]">
          <form
            name="forgot_password"
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className="w-full">
              <div className="w-full flex flex-col relative">
                <TextInput
                  type={'text'}
                  name={'email'}
                  id={'email'}
                  label={'Username/email address'}
                  placeholder={'Username or email address'}
                  className={'w-full input'}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.errors.email && formik?.touched.email ? (
                  <ErrorMsg value={formik.errors.email} />
                ) : null}
              </div>
            </div>

            <div>
              <Button
                type="submit"
                text="Submit"
                loading={formik.isSubmitting}
                disabled={formik.isSubmitting}
                className="submit-btn bg-black hover:bg-primary text-[20px] font-sofiaMedium font-medium w-full  py-3 text-white rounded-[35px] mt-8 mb-3 transition-all ease-in"
              />
            </div>
            <div className="flex justify-center">
              <Link
                to="/login"
                className="underline w-fit text-center text-[#8D8D8D] hover:md:text-black font-sofiaRegular font-regular text-18"
                aria-disabled={formik.isSubmitting}
              >
                Back to Login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordContent;
